import React, {useState} from 'react';
import Navbar from './Navbar';
import Select from 'react-select';
import axios from 'axios';
import config from '../config.json';

export default function InsertMood() {
  const rateOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' }
  ];
  const booleanOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' }
  ]

  const [selectedEnergyLevel, setSelectedEnergyLevel] = useState();
  const [selectedHappinessLevel, setSelectedHappinessLevel] = useState();
  const [selectedStressLevel, setSelectedStressLevel] = useState();
  const [selectedBoredom, setSelectedBoredom] = useState();
  const [description, setDescription] = useState();
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(false);

  const changeEnergyLevel = (e) => {
    setSelectedEnergyLevel(e);
  }
  const changeHappinessLevel = (e) => {
    setSelectedHappinessLevel(e);
  }
  const changeStressLevel = (e) => {
    setSelectedStressLevel(e);
  }
  const changeBoredom = (e) => {
    setSelectedBoredom(e);
  }

  const submitMood = () => {
    setLoading(true);
    if (selectedEnergyLevel && selectedHappinessLevel && selectedStressLevel && selectedBoredom && description) {
      var data = {
        energy: selectedEnergyLevel.value,
        happiness: selectedHappinessLevel.value,
        stress: selectedStressLevel.value,
        boredom: selectedBoredom.value,
        description: description
      };
      axios.post(config.BASE_URL + "/api/insert-mood", data)
      .then((response) => {
        setLoading(false);
        if (response.data.status == "OK") {
          setAnswer(response.data.data);
        }
        else {
          alert(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err.message);
      });
    }
    else {
      setLoading(false);
      alert("Please fill all the fields.");
    }
  }

  return (
    <>
      <Navbar />
      <div className="container mt-3">
        <div style={{textAlign: "center"}}>
          <h3>Insert Mood</h3>
        </div>
        <div className="form mb-3">
          <div className="form-group">
            <label>Rate your happiness level from 1 to 5</label>
            <Select options={rateOptions} value={selectedHappinessLevel} onChange={changeHappinessLevel} />
          </div>
          <div className="form-group">
            <label>Rate your energy level from 1 to 5</label>
            <Select options={rateOptions} value={selectedEnergyLevel} onChange={changeEnergyLevel} />
          </div>
          <div className="form-group">
            <label>Rate your stress level from 1 to 5</label>
            <Select options={rateOptions} value={selectedStressLevel} onChange={changeStressLevel} />
          </div>
          <div className="form-group">
            <label>Do you feel bored?</label>
            <Select options={booleanOptions} value={selectedBoredom} onChange={changeBoredom} />
          </div>
          <div className="form-group mb-3">
            <label>Describe your current mood in a few words.</label>
            <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} rows={5}></textarea>
          </div>
          <div style={{textAlign: "right"}}>
            <button className="btn btn-primary" onClick={submitMood}>Submit</button>
          </div>
        </div>
        <div className="form">
          <p><b>PsychBot</b></p>
          {loading && (
            <div style={{textAlign: 'center'}}>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <p>{answer}</p>
        </div>
      </div>
    </>
  )
}
