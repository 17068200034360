import React, {useState} from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import config from '../config.json';

export default function Chat() {
  const [chat, setChat] = useState([]);
  const [idx, setIdx] = useState(0);
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(false);

  const newChat = () => {
    setChat([]);
    setIdx(0);
  }

  const submitChat = () => {
    setLoading(true);
    if (content) {
      var data = {
        chat: [...chat, {role: "user", content: content}],
        idx: idx
      };
      axios.post(config.BASE_URL + "/api/insert-chat", data)
      .then((response) => {
        setLoading(false);
        if (response.data.status == "OK") {
          setChat([...chat, {role: "user", content: content}, response.data.data]);
          setIdx(idx + 2);
          setContent("");
        }
        else {
          alert(response.data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err.message);
      });
    }
    else {
      setLoading(false);
      alert("Please fill all the fields.");
    }
  }

  return (
    <>
      <Navbar />
      <div className="small-container mt-3">
        <div style={{textAlign: "center"}}>
          <h3>Chat</h3>
        </div>
        <div style={{textAlign: "right"}}>
          <button className="btn btn-primary" onClick={newChat}>New Chat</button>
        </div>
        <div className="chat-form mt-3">
          <div className="form-group mb-3">
            <textarea className="form-control" value={content} onChange={(e) => setContent(e.target.value)}rows={5}></textarea>
          </div>
          <div style={{textAlign: "right"}}>
            <button className="btn btn-primary" onClick={submitChat}>Send</button>
          </div>
        </div>
        {loading && (
          <div style={{textAlign: 'center'}}>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="chat-list">
          {chat.map((item) => (
            <div className="chat-item">
              <p><b>{item.role == "user" ? "User" : "PsychBot"}</b></p>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
